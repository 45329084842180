.faq-container {
    max-width: 1600px;
    margin: auto;
    padding: 50px;
  }
  
  .question-section {
    background-color: #f6f6f6;
    padding: 30px;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 22px;
    font-weight: bold;
    transition: all 0.4s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .question-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .question-icon {
    margin-left: 5px;
    font-size: 1.5rem;
  }
  
  .question-style {
    margin: 0;
  }
  
  .answer {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s;
  }
  
  .answer-show {
    max-height: 200px;
    transition: all 0.4s;
  }
  
  
  .answer-text {
    font-size: 22px;
    margin-top: 15px;
  }
  
  .answer p:last-child {
    margin-bottom: 0;
  }
  
/* Footer section */
footer {
    background-color: #1f068ec3;
    padding: 60px 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  footer .container > * {
    width: 100%;
    max-width: 360px;
    margin-bottom: 0px;
  }
  
  footer .logo {
    width: 300px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  footer h2 {
    font-size: 18px;
    font-weight: 600;
    color: #1D1D1D;
    margin-bottom: 20px;
  }
  
  footer ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  footer ul li {
    margin-bottom: 10px;
  }
  
  footer ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #1D1D1D;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }
  
  footer ul li a:hover {
    color: #565656;
  }
  
  footer .border-line {
    width: 100%;
    height: 1px;
    background-color: #6e40d1;
    margin: 20px 0;
  }
  
  footer .social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  footer .social-icons li {
    margin-right: 20px;
  }
  
  footer .social-icons li:last-child {
    margin-right: 0;
  }
  
  footer .social-icons li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #1D1D1D;
    color: #FFFFFF;
    transition: background-color 0.2s ease-in-out;
  }
  
  footer .social-icons li a:hover {
    background-color: #565656;
  }
  
  footer .copy-rights {
    font-size: 12px;
    font-weight: 400;
    color: #565656;
    margin-top: 40px;
  }
  
  @media (min-width: 768px) {
    footer .container {
      flex-wrap: nowrap;
    }
  
    footer .container > * {
      margin-bottom: 0;
    }
  
    footer .useful-links {
      margin-right: 40px;
    }
  
    footer .legal {
      margin-left: 40px;
    }
  
    footer .border-line {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    footer .container {
      justify-content: space-between;
      align-items: flex-start;
    }
  
    footer .container > * {
      margin-bottom: 0;
    }
  
    footer .useful-links {
      margin-right: 0;
    }
  
    footer .legal {
      margin-left: 0;
    }
  
    footer .border-line {
      display: block;
    }
  
    footer .social-icons {
      justify-content: flex-end;
    }
  }
  
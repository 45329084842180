.header {
  position: relative;
  transition: all 0.3s ease-in-out;
  z-index: 200;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
}

.header__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.header__logo {
  text-decoration: none;
}

.header__logo img {
  margin: 0;
}

.header__nav {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background: #fff;
  flex-direction: column;
  text-align: center;
  overflow-y: hidden;
  z-index: 1500;
  padding-top: 2rem;
}

.header__nav.open {
  display: flex;
}

.header__link {
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  color: #333;
  padding: 1rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.header__link:hover {
  color: #fbbf24;
}

.menu-button {
  position: relative;
  display: block;
  cursor: pointer;
  width: 1.5rem;
  height: 1rem;
  padding: 0;
  background: none;
  border: none;
  z-index: 10;
  margin-right: -1.5rem;
}

.menu-button span {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #333;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.menu-button span:nth-child(1) {
  top: 0px;
}

.menu-button.showMenu span:nth-child(1) {
  top: 8px;
  transform: rotate(45deg) translate(4px, 4px);
}

.menu-button span:nth-child(2) {
  top: 8px;
}

.menu-button.showMenu span:nth-child(2) {
  opacity: 0;
}

.menu-button span:nth-child(3) {
  top: 16px;
}

.menu-button.showMenu span:nth-child(3) {
  top: 8px;
  transform: rotate(-45deg) translate(4px, -4px);
}

@media screen and (min-width: 768px) {
  .header__nav {
    display: flex;
    position: static;
    flex-direction: row;
    background: none;
    overflow: visible;
    padding-top: 0;
  }

  .header__link {
    margin-right: 2rem;
    padding: 0;
  }

  .menu-button {
    display: none;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap");
* {
  font-family: "Inter", sans-serif;
  /* overflow: auto; */
}
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img {
  width: 80px;
}

.navigation {
  display: flex;
  align-items: center;
}

.toggle-button {
  display: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
}

.bar {
  display: block;
  width: 20px;
  height: 3px;
  background-color: var(--secondary-color);
  margin-bottom: 3px;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links li {
  list-style: none;
}

.links li a {
  display: block;
  padding: 1rem;
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 1.2rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.links li a:hover {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .toggle-button {
    display: block;
  }

  .links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .links li a {
    color: var(--primary-color);
  }

  .links li a:hover {
    color: var(--primary-color);
    background-color: #f0f0f0;
  }
}


.main__background{
  background-color: #E5E5E5
}
.intro {
  background: linear-gradient(180deg, #e9edfb 0%, rgba(233, 237, 251, 0) 100%) !important;
}
.network {
  background: #e9edfb;
}
footer {
  background: #f6f7fb;
}
.app-store {
  background: #fdeded;
}

.padding-all {
  padding: "120px";
}

/* Carrier */
.carrier_reason {
  background: #f6f7fb;
  border-radius: 8px;
}

/* ABout us */
.about__image {
  position: relative;
  bottom: 200px;
}


/* FAQs css */

.question-section {
  background: transparent;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  cursor: pointer;
  width: 92vw;
}

.question-align {
  align-items: center;
  display: flex;
  min-height: 10px;
  text-align: left;
}

.question-align h4 {
  margin-left: 8px;
}

.question-styling {
  font-size: 17px;
  width: 100%;
}

.question-icon {
  background: none;
  border: none;
  color: #1b6aae;
  cursor: pointer;
  font-size: 22px;
  margin-left: auto;
  margin-right: 8px;
}

.rotate {
  transform: rotate(45deg);
}

.answer-divider {
  border-top: 1px solid lightgray;
}

.answer {
  margin-left: 8px;
  margin-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.active {
  background-color: #E9EDFB;
  color:#274AD8;
  border-right: 3px solid #274AD8 ;
  text-decoration: none !important;
  width: 100%;

}

.o-container {
  @apply mx-auto px-4 w-full;
}

.o-container--scroller {
  @apply mx-auto px-0 pr-0;
}

@media only screen and (min-width: 375px) {
  .o-container,
  .o-container--scroller {
    max-width: 1236px !important;
  }
}

@media only screen and (min-width: 768px) {
  .o-container,
  .o-container--scroller {
    @apply px-12 !important;
  }
}
@media only screen and (min-width: 1024px)  {
  .o-container,
  .o-container--scroller {
    @apply px-4 !important;
  }
}

.o-show {
  display: block !important;
}
